import { DOMAIN_ANCHR } from '@constants/constants';
import { getConfig } from '@lib/prepr/config';
import { fetchHomePage } from '@lib/prepr/home';
import HomeTemplate from '@templates/HomeTemplate/HomeTemplate';
import { Locale } from '@type-declarations/locale';
import { HomePage as HomePageProps } from '@type-declarations/page';
import { NextApiRequest } from 'next';

interface Props {
  page: HomePageProps;
  host: string;
}

export default function HomePage({ page, host }: Props) {
  return <HomeTemplate page={page} host={host} />;
}

export async function getServerSideProps({
  req,
  locale,
  draftMode: preview = false,
}: {
  req: NextApiRequest;
  locale: Locale;
  draftMode: boolean;
}) {
  const { host = DOMAIN_ANCHR } = req.headers;
  const { __prepr_uid: customerId = '' } = req.cookies;
  const [page, config] = await Promise.all([
    fetchHomePage({ host, locale, customerId, preview }),
    getConfig({ host, locale, preview }),
  ]);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      host,
      locale,
      preview,
      page,
      config,
    },
  };
}
